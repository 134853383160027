import { formatDateTime } from '@/utils/format/date'

function transformTradeOfferLots (lots) {
  return lots.map(lot => ({
    id: lot.id,
    name: lot.name,
    bidderCounter: lot.bidder_count,
    date: [
      { title: 'Дата начала и время публикации', description: formatDateTime(lot.published_at) },
      { title: 'Дата и время начала ТП', description: formatDateTime(lot.started_at) },
      { title: 'Дата и время окончания ТП', description: formatDateTime(lot.duration) },
      {
        title: 'Статус',
        description: lot.status.name,
        decorator: { name: 'Status' }
      }
    ]
  }))
}

export {
  transformTradeOfferLots
}
