<script>
import { goBack, goToPage } from '@/utils/goRoute'
import { reactive, watch } from 'vue'
import VSummarizing from '@/components/VSummarizing'
import VSearch from '@/components/ui/form/Search/VSearch'
import { useFilter } from '@/use/Base/useFilter'
import { useGetItem } from '@/use/Base/item/useGetItem'
import { transformTradeOfferLots } from '@/utils/model/Lot/transform'
import SpinnerPage from '@/components/views/Spinner/SpinnerPage'
import { useRoute } from 'vue-router'

export default {
  props: {
    titlePage: {
      type: String,
      required: false
    }
  },
  setup (_, { emit }) {
    const route = useRoute()
    const goSummarizingItem = id => goToPage('organizer-summarizing-lot', { id })

    const configuration = reactive({
      transformObject: transformTradeOfferLots,
      urlSetRows: '/trade-offer/lots',
      requestIsAuth: true,
      urlActionQuery: 'search[MyLot]&expand=status',
      sort: '-duration'
    })

    const { input } = useFilter()
    const { getIsLoad, getItemInStore, getItem } = useGetItem(emit, 1000, configuration)

    watch(() => route.name, async routeName => {
      if (routeName !== 'organizer-register') return
      await getItem()
    })

    return {
      getIsLoad,
      getItemInStore,
      goSummarizingItem,
      goBack,
      input
    }
  },
  components: {
    SpinnerPage,
    VSummarizing,
    VSearch
  }
}
</script>

<template>
  <div v-if="$route.name === 'organizer-register'">
    <div class="block__subtitle mt-40 mb-20">
      Подведение итогов
    </div>

    <div class="block__control">
      <div class="block__field">
        <VSearch
          name="searchManual"
          placeholder="Поиск"
          class-input="input-border-all input-padding-25 input-height-48"
          v-model="input"
        />
      </div>
    </div>

    <SpinnerPage v-if="getIsLoad" />

    <div v-if="getItemInStore.length && !getIsLoad">
      <VSummarizing
        v-for="lot in getItemInStore"
        :key="lot.id"
        :lot="lot"
        @to="goSummarizingItem"
      />
    </div>

    <div v-else-if="!getItemInStore.length && !getIsLoad">Лоты не найдены</div>
  </div>

  <router-view />
</template>
